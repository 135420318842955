<template>
    <div>
        <van-nav-bar title="联系客服" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="my_service_box" v-if="staffData.length">
            <div class="contract_box" v-for="(item, index) in  staffData " :key="index">
                <div class="contract">
                    <img src="../../assets/img/kefu.png" class="contract_img" />
                    <div class="contract_info">
                        <div class="name">{{ item.name }}</div>
                        <div class="intro">{{ item.area_label }}</div>
                        <div class="contract_btn">
                            <van-button class="btn1" size="mini" type="primary" v-if="item.company_weixin_link" :url="item.company_weixin_link">在线咨询</van-button>
                            <van-button class="btn2" size="mini" plain="true" v-if="item.mobile " @click="toCall(item.mobile)">电话咨询</van-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty description="暂无客服" />
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
export default {
    setup() {
        const onClickLeft = () => history.back();
        const staffData = ref([]);
        onBeforeMount(()=>{
            loadContractData()
        });
        const loadContractData = ()=>{
            request({
                url:'/app/contact_customer',
                method:'GET'
            }).then((res)=>{
                if(res&&res.item&&res.item.length){
                    staffData.value=res.item
                }
            })
        };
        const toCall = (tel)=>{
            window.location.href='tel://'+tel
        };
        return{
            onClickLeft,
            staffData,
            toCall,
        }
    },
}
</script>

<style>
.contract_box{padding: 10px;}
.contract_box .contract{display: flex;}
.contract_box .contract .contract_img{width: 100px;height: 100px;margin-right: 10px;border: 1px solid #ededed;border-radius: 10px;}
.contract_box .contract .contract_info{flex: 1;}
.contract_box .contract .contract_info .name{color: #4a4a4a;}
.contract_box .contract .contract_info .intro{color: #969799;font-size: 12px;height: 40px;overflow: hidden;word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;margin-bottom: 10px;word-break:break-word;}
.contract_box .contract .contract_info .contract_btn{}
.contract_box .contract .contract_info .contract_btn .btn1{font-size: 12px;width: 45%;border-color: #07C160;color: #07C160;background-color: #ffffff;}
.contract_box .contract .contract_info .contract_btn .btn2{font-size: 12px;width: 45%;border-color: #07C160;background-color: #07C160;color: #ffffff;float: right;}
</style>